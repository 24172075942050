import React from "react"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.en"

const IndexEN = ({ location }) => {
  return (
    <>
      <Metatags
        title="Multilógica | Elektroteks Acquires Mammut"
        description="Mattress machinery supplier"
        url="https://newsite.multilogica.ind.br/en/privacy-policy"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="mattress, sewing machine, mammut, beckmann sew"
        language="en"
      />
      <NavBar location={location["pathname"]} />
      <div className="h-20" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mx-2">
          <div className="col-span-2" />
          <div className="col-span-8 text-center">
            <div className="text-5xl font-semibold text-mi-blue">
              Elektroteks Acquires Mammut
            </div>
            <div className=" h-8" />
            <div className=" text-left text-xl">
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                <b>Bursa, Türkiye / Cologne, Germany - [2 October 2024]</b> -
                Elektroteks, the world's largest mattress and foam machinery
                manufacturer, is proud to announce its acquisition of 100% of
                the shares of Mammut (Nähmaschinenfabrik Emil Stutznäcker GmbH &
                Co KG), the leader in quilting machine manufacturer in the
                world. With over 140 years of history, Mammut is known for
                producing the highest quality quilting machines, setting the
                standard for precision and craftsmanship in the industry. The
                deal was officially closed on 23rd of September.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Elektroteks</b>, headquartered in Bursa, Türkiye, is a
                family-owned company with a global presence in 133 countries. As
                the largest machinery producer for the mattress and foam
                industries, Elektroteks has built a reputation for innovation,
                customer satisfaction, and a robust global service network. With
                350 employees and a cutting-edge 65,000 sqm production facility,
                the company continues to deliver industry-leading solutions.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Mammut</b>, based in Cologne, Germany, has been at the
                forefront of quilting technology for over a century, crafting
                the world's most precise quilting machines. Elektroteks is fully
                committed to preserving this heritage while infusing new energy
                and ideas into the Mammut brand.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>
                  Michael Pavlidis will remain as Managing Director of Mammut
                </b>
                , ensuring continuity and leadership during this exciting new
                chapter. His expertise and dedication will be crucial in
                steering the company through upcoming projects and innovations.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Serkan Guler, CEO of Elektroteks</b>, stated: “This
                acquisition is a powerful combination of two industry leaders.
                We are honored to carry forward Mammut's legacy, the oldest and
                most respected name in quilting machines, and look forward to
                collaborating on new projects that will bring cutting-edge
                solutions to the market.”
              </p>
              <p>&nbsp;</p>
              <p>
                As part of this integration, Elektroteks will expand Mammut's
                spare parts and service operations using its extensive global
                network. The headquarters and production of Mammut will remain
                in Germany, preserving its strong heritage. Exciting new
                projects are already in the pipeline, promising to further
                enhance Mammut's offerings in the quilting machinery industry.
              </p>
              <p>&nbsp;</p>
              <p>
                Elektroteks' acquisition of Mammut heralds a new era of growth
                and innovation, leveraging the strengths of both companies to
                provide superior products and services to the global mattress
                and foam industries.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>For more information</b>, please contact:
                <br />
                info@elektroteks.com
                <br />
                sales@mammut.de
              </p>
            </div>
          </div>
        </div>
        <div className="h-10" />
      </div>
      <div className="h-20" />
      <div className="h-10" />
      <Footer />
    </>
  )
}

export default IndexEN
